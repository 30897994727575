<template>
  <section>
    <b-tabs content-class="mt-1">
      <b-tab title="ใช้ข้อกำหนดและเงื่อนไขที่กำหนดเอง">
        <div
          align="center"
          style="
            border-style: solid;
            border-color: rgb(169, 169, 169);
            border-radius: 10px;
            width: 120px;
            padding-top: 5px;
          "
          v-clipboard:copy="url_line_terms"
          variant="primary"
          @click="onCopy()"
        >
          <h4 style="color: #000">คัดลอก URL</h4>
        </div>
        <br />
        <div v-html="terms" />
      </b-tab>

      <b-tab title="ใช้นโยบายความเป็นส่วนตัวที่กำหนดเอง" lazy>
        <div
         align="center"
          style="
            border-style: solid;
            border-color: rgb(169, 169, 169);
            border-radius: 10px;
            width: 120px;
            padding-top: 5px;
          "
          v-clipboard:copy="url_line_service"
          variant="primary"
          @click="onCopy()"
        >
          <h4 style="color: #000">คัดลอก URL</h4>
        </div>
        <br />
        <div v-html="service" />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BModal,
  VBModal,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BForm,
  BFormText,
  BFormDatalist,
  BFormSelect,
  BFormTextarea,
  BTabs,
  BTab,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BModal,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BForm,
    BFormText,
    BFormDatalist,
    BFormSelect,
    BFormTextarea,
    BTabs,
    BTab,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      terms: null,
      service: null,
      url_line_terms: "",
      url_line_service: "",
    };
  },
  mounted() {
    this.loaddata();
  },

  methods: {
    loaddata() {
      this.url_line_terms =
        "https://api.socio.co.th/consent?id=" +
        localStorage.getItem("store_id") +
        "&type=1";
      this.url_line_service =
        "https://api.socio.co.th/consent?id=" +
        localStorage.getItem("store_id") +
        "&type=2";
      var url =
        "https://api.socio.co.th/api/consent?id=" +
        localStorage.getItem("store_id");
      axios
        .get(url)
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.terms = response.data.terms;
          this.service = response.data.service;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "คัดลอกลิงค์เรียบร้อย",
          icon: "BellIcon",
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>